import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { ImageList } from '../../components/Gallery'
import Layout from '../../components/Layout'
import HeroNobackground from '../../components/HeroNoBackground'

if (typeof window !== `undefined`) {
  window.imagesToShow = 12
}

const flattenAlbumImages = (allAlbum) => {
  const images = allAlbum.edges.map(({ node }) => {
    return node.images
  }).reduce((prev, curr) => {
    return prev.concat(curr)
  })

  return images
}

const AllImages = (props) => {
  let imagesToShow = 12

  if (typeof window !== `undefined`) {
    imagesToShow = window.imagesToShow
  }

  const [count, setCount] = useState(imagesToShow)

  useEffect(() => {
    return () => {
      window.postsToShow = count
    }
  })
  
  const { allContentfulAlbum } = props.data

  const images = flattenAlbumImages(allContentfulAlbum)

  const title = `All Images`
  
  const reverseArray = arr => {
    let newArray = []
    for (let i = arr.length - 1; i >= 0; i--) {
      newArray.push(arr[i])
    }

    return newArray
  }

  const reversedImages = reverseArray(images)

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <HeroNobackground title={`${title}`} />
      <ImageList
        images={reversedImages}
        count={count}
        onButtonClick={() => setCount(count + 12)}
      />
    </Layout>
  )
}

export default AllImages

export const pageQuery = graphql`
  query {
    allContentfulAlbum(sort: { order: ASC, fields: updatedAt}) {
      edges {
        node {
          title
          images {
            title
            id
            thumbnail: fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`